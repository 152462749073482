<template>
  <v-app-bar app clipped-left color="var(--company-primary)" elevation="0">
    <v-app-bar-nav-icon
      class="d-block d-lg-none"
      color="var(--white)"
      @click="$emit('open-drawer', !drawer)"
    ></v-app-bar-nav-icon>
    <v-btn
      v-if="backLink"
      class="align-self-center mr-xl-n10"
      icon
      elevation="0"
      color="var(--white)"
      :to="backLink"
    >
      <v-icon icon="mdi-arrow-left"
    /></v-btn>

    <v-container>
      <h4 v-if="topbartitle" class="text-white align-self-center">
        {{ topbartitle }}
      </h4>
      <h4 v-else class="text-white align-self-center">
        {{
          mainstore.companypage.data != undefined
            ? $i18n.locale == "nl"
              ? mainstore.companypage.data.nl.title
              : mainstore.companypage.data.eng.title
            : ""
        }}
      </h4>
    </v-container>
    <v-btn
      v-if="mainstore.user.id != -1"
      color="var(--white)"
      class="d-flex align-self-center ml-auto"
      variant="text"
      icon
      :href="`tel:${
        mainstore.companypage.data != undefined
          ? mainstore.companypage.data.contact.phonenumber
          : '+31 085 303 8194'
      }`"
    >
      <svg
        class="align-self-center mx-auto d-block"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.95 21C17.8667 21 15.8083 20.5458 13.775 19.6375C11.7417 18.7292 9.89167 17.4417 8.225 15.775C6.55833 14.1083 5.27083 12.2583 4.3625 10.225C3.45417 8.19167 3 6.13333 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.07917 8.725 3.2375C8.90833 3.39583 9.01667 3.58333 9.05 3.8L9.7 7.3C9.73333 7.56667 9.725 7.79167 9.675 7.975C9.625 8.15833 9.53333 8.31667 9.4 8.45L6.975 10.9C7.30833 11.5167 7.70417 12.1125 8.1625 12.6875C8.62083 13.2625 9.125 13.8167 9.675 14.35C10.1917 14.8667 10.7333 15.3458 11.3 15.7875C11.8667 16.2292 12.4667 16.6333 13.1 17L15.45 14.65C15.6 14.5 15.7958 14.3875 16.0375 14.3125C16.2792 14.2375 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15.0167 20.625 15.1375 20.775 15.3125C20.925 15.4875 21 15.6833 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21ZM6.025 9L7.675 7.35L7.25 5H5.025C5.10833 5.68333 5.225 6.35833 5.375 7.025C5.525 7.69167 5.74167 8.35 6.025 9ZM14.975 17.95C15.625 18.2333 16.2875 18.4583 16.9625 18.625C17.6375 18.7917 18.3167 18.9 19 18.95V16.75L16.65 16.275L14.975 17.95Z"
          fill="white"
        />
      </svg>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { useMainStore } from "@/stores/store";
export default {
  setup() {
    // const mainStore = useMainStore();
    // return {
    //   company_topbar_title:
    //     mainStore.companypage.data != undefined
    //       ? i18n.locale == "nl"
    //         ? mainStore.companypage.data.nl.quote
    //         : mainStore.companypage.data.eng.quote
    //       : "",
    //   company_phonenumber:
    //     mainStore.companypage.data != undefined
    //       ? mainStore.companypage.data.contact.phonenumber
    //       : "+31 085 303 8194",
    //   logged_in: mainStore.user.id != -1,
    // };
  },

  props: {
    topbartitle: {
      type: String,
      required: false,
    },
    backLink: {
      type: String,
      required: false,
    },
    drawer: Boolean,
  },
  data: () => ({
    mainstore: useMainStore(),
  }),
  created() {
    if (window.innerWidth > 1199) {
      this.$emit("open-drawer", true);
    }
  },
};
</script>

<style></style>
